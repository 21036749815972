import r1 from './img/1.jfif'
import r2 from './img/2.jfif'
import r3 from './img/3.jfif'
import r4 from './img/4.jfif'
import r5 from './img/5.jfif'
import r6 from './img/6.bmp'
import r7 from './img/7.bmp'
import r8 from './img/8.bmp'
import r9 from './img/9.bmp'
import r10 from './img/10.bmp'
import r11 from './img/11.bmp'
import r12 from './img/12.jfif'
import r13 from './img/13.jfif'
import r14 from './img/14.jfif'
import r15 from './img/15.jfif'
import r16 from './img/16.jfif'
import r17 from './img/17.bmp'
import r17a from './img/17-1.bmp'
import r18 from './img/18.jfif'
import r19 from './img/19.jfif'
import r20 from './img/20.jfif'
import r21 from './img/21.jfif'
import r22 from './img/22.jfif'
import r23 from './img/23.jfif'
import r24 from './img/24.jfif'
import r25 from './img/25.jfif'
import r26 from './img/26.jfif'
import r27 from './img/27.jfif'
import r28 from './img/28.jfif'

export const productImg = {
  r1,
  r2,
  r3,
  r4,
  r5,
  r6,
  r7,
  r8,
  r9,
  r10,
  r11,
  r12,
  r13,
  r14,
  r15,
  r16,
  r17,
  r17a,
  r18,
  r19,
  r20,
  r21,
  r22,
  r23,
  r24,
  r25,
  r26,
  r27,
  r28,
}
