import aser from './partners/acer.png';
import aquarius from './partners/aquarius.png';
import brother from './partners/brother.png';
import cyberpower from './partners/cyberpower.png';
import defender from './partners/defender.png';
import depo from './partners/depo.png';
import iek from './partners/iek.png';
import kraftway from './partners/kraftway.png';
import krauler from './partners/krauler.png';
import lexmark from './partners/lexmark.png';
import mikrolab from './partners/mikrolab.png';
import packard_bell from './partners/packard_bell.png';
import electric from './partners/electric.jpg';
import tenda from './partners/tenda.jpg';



export const partners = { aser, aquarius, brother, cyberpower, defender, depo, iek, kraftway, krauler, lexmark, mikrolab, packard_bell, electric, tenda };