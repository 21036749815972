import conf from './conf.png'
import doc from './doc.png'
import organizer from './organizer.png'
import pleer from './pleer.png'
import presentation from './presentation.png'
import signFlat from './sign_flat.png'
import tables from './tables.png'
import uninstall1 from './uninstall_1.png'
import viewer from './viewer.png'

export const iconsR7 = {
  conf,
  doc,
  organizer,
  pleer,
  presentation,
  signFlat,
  tables,
  uninstall1,
  viewer,
}
